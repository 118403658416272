<template>
  <modal-wrapper :id="id" hide-footer size="md">
    <template #title>
      Importa immagine
    </template>
    <template #body>
      <vue-dropzone
        ref="imgDropZone"
        :id="dropZoneId"
        :options="dropzoneOptions"
        @vdropzone-success="afterSuccess"
        @vdropzone-complete="afterComplete"
      ></vue-dropzone>
    </template>
  </modal-wrapper>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import { extensionImages, getEntityApiBase, mimeTypeImages } from '@/utils/documents';
import TokenService from '@/services/token.service';
import { isNotEmpty } from '@/utils/validators';

const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalImageUpload',
  props: {
    id: String,
    imageType: String,
    entityType: String,
    entityId: String,
    onSuccess: Function,
  },
  components: { ModalWrapper, vueDropzone: vue2Dropzone },
  computed: {
    dropZoneId() {
      return `${this.id}_dropzone`;
    },
    dropzoneOptions() {
      const apiBase = getEntityApiBase(this.entityType); // apiBase || '/credit-api';
      const url = `${apiBase}/api/subject-image/${this.entityType}/${this.entityId}/${this.imageType}`;

      return {
        url,
        thumbnailWidth: 350,
        thumbnailHeight: 100,
        addRemoveLinks: false,
        acceptedFiles: mimeTypeImages.join(),
        headers: TokenService.authHeader(),
        dictDefaultMessage: `<p class='text-default'>
                                <font-awesome-icon icon="cloud-upload-alt"></font-awesome-icon>
                                 Trascina un documento o fai click in quest'area
                             </p>
          <p class="form-text">File accettati: ${extensionImages.join()}</p>
          `,
      };
    },
  },
  methods: {
    //         v-on:vdropzone-sending="sendingEvent"
    // sendingEvent(file, xhr, formData) {
    //   //formData.append('paramName', 'some value or other');
    // },
    async afterSuccess(file, response) {
      console.log('response', response);
      this.$store.dispatch('subject/setImage', {
        imageType: this.imageType,
        image: response,
      });
      if (isNotEmpty(this.onSuccess)) {
        this.onSuccess(response);
      }
    },
    async afterComplete() {
      this.$bvModal.hide(this.id);
    },
  },
};
</script>

<style scoped>

</style>
